// MeetingPopup.js
import React, { useState } from "react";
import styled from "styled-components";

const MeetingPopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  z-index: 999;
  backdrop-filter: blur(5px);
  max-width: 80%;
  width: 400px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 2px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

const EditButton = styled(Button)`
  background-color: #ffc107;
  margin-left: 10px;
`;

const CancelButton = styled(Button)`
  background-color: #dc3545;
`;

const InfoText = styled.p`
  font-size: 16px;
  margin-bottom: 15px;
`;

const PopupTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const MeetingLink = styled.a`
  color: #007bff; /* Link color */
  display: block;
  padding: 5px;
  margin: 10px 0;
  text-decoration: underline; /* Underline the link */
  cursor: pointer; /* Show pointer cursor on hover */
  font-size: 16px; /* Font size */
  /* Add more styles as needed */
`;

const MeetingPopup = ({ meetingLink, onSave, setShowMeetingPopup }) => {
  const [editedMeetingLink, setEditedMeetingLink] = useState(meetingLink);
  const [isEditing, setIsEditing] = useState(false);

  const handleSaveMeetingLink = () => {
    onSave(editedMeetingLink);
    setIsEditing(false);
    setShowMeetingPopup(false); // Close popup after creating or editing
  };

  return (
    <MeetingPopupContainer>
      {isEditing ? (
        <>
          <PopupTitle>Edit Meeting Link</PopupTitle>
          <p>Meeting Link: {meetingLink}</p>
          <Input
            type="text"
            value={editedMeetingLink}
            onChange={(e) => setEditedMeetingLink(e.target.value)}
          />
          <EditButton onClick={handleSaveMeetingLink}>Save</EditButton>
          <CancelButton onClick={() => setIsEditing(false)}>
            Cancel
          </CancelButton>
        </>
      ) : (
        <>
          {!meetingLink && (
            <InfoText>
              Enter the meeting link of your preferred app (e.g., Google Meet or
              Zoom).
            </InfoText>
          )}
          <PopupTitle>
            {meetingLink ? "Meeting Link" : "Enter Meeting Link"}
          </PopupTitle>
          {/* {meetingLink && <p>Meeting Link: {meetingLink}</p>} */}
          {meetingLink ? (
            <MeetingLink
              href={
                meetingLink.includes("http")
                  ? meetingLink
                  : `http://${meetingLink}`
              }
              target="_blank"
            >
              {editedMeetingLink}
            </MeetingLink>
          ) : (
            <Input
              type="text"
              value={editedMeetingLink}
              onChange={(e) => setEditedMeetingLink(e.target.value)}
            />
          )}

          <Button
            onClick={
              meetingLink ? () => setIsEditing(true) : handleSaveMeetingLink
            }
          >
            {meetingLink ? "Edit" : "Create"}
          </Button>
          
            <CancelButton onClick={() => setShowMeetingPopup(false)}>
              Close
            </CancelButton>
          
        </>
      )}
    </MeetingPopupContainer>
  );
};

export default MeetingPopup;
