import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Set a high z-index value */
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const RewardModal = ({ onClose, reason, coinsCount }) => {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowConfetti(false);
      onClose();
    }, 5000); // Close modal after 5 seconds
  }, [onClose]);

  return (
    <ModalContainer>
      <ModalContent>
        {showConfetti && <Confetti />}
        <h2>Congratulations!</h2>
        <p>
          You have been rewarded with {coinsCount} skill coins for {reason}.
        </p>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </ModalContent>
    </ModalContainer>
  );
};

export default RewardModal;
