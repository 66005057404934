import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import RewardModal from "././rewardModel";
import API_URL from "../../config";
import { SKILL_COIN_GAME } from "../../config";

const Game = () => {
	const [hiddenWord, setHiddenWord] = React.useState("");
	const [word, setWord] = React.useState("");
	const [remainingAttempts, setRemainingAttempts] = React.useState(6);
	const [game, setGame] = useState({});
	const [showPopup, setShowPopup] = useState(false);
	const [isGameWon, setIsGameWon] = React.useState(false);
	const { userID } = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [isRewarded, setIsRewarded] = useState(false);

	const apiUrl = API_URL;
	const [showRewardModal, setShowRewardModal] = useState({
		show: false,
		reason: "",
		coinsCount: 0,
	});

	useEffect(() => {
		const token = localStorage.getItem("jwt");
		if (!token) {
			// Handle the case where the token is not present
			console.log("JWT token not found in localStorage");
			navigate("/register");
			return;
		}
		try {
			const fetchGameDetails = async () => {
				const response = await axios.get(`${apiUrl}/game/${userID}/details`);
				setGame(response.data.game);
				console.log(response.data.game);
				if (response.data.game.canPlay === false) {
					const lastPlayed = new Date(response.data.game.lastPlayed);
					const now = new Date();
					const diffTime = Math.abs(now - lastPlayed);
					const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
					console.log(diffDays);
					if (diffDays < 1) {
						setShowPopup(true);
						setLoading(false);
						return;
					} else {
						console.log("Game can be played");
						const generateHiddenWord = (word) => {
							return "_ ".repeat(word.length);
						};

						const initialWord = "apple";
						setWord(initialWord);
						setHiddenWord(generateHiddenWord(initialWord));
						setLoading(false);
					}
				} else {
					const generateHiddenWord = (word) => {
						return "_ ".repeat(word.length);
					};

					const initialWord = "apple";
					setWord(initialWord);
					setHiddenWord(generateHiddenWord(initialWord));
					setLoading(false);
				}
			};
			fetchGameDetails();
		} catch (err) {
			console.error("Failed to fetch game details:", err);
		}
	}, []);

	const handleGuess = (letter) => {
		if (word.includes(letter)) {
			setHiddenWord((prevHiddenWord) => {
				let newHiddenWord = "";
				for (let i = 0; i < word.length; i++) {
					if (word[i].toLowerCase() === letter) {
						newHiddenWord += letter + " ";
					} else {
						newHiddenWord += prevHiddenWord[i * 2] + " ";
					}
				}
				console.log(newHiddenWord.trim());
				if (word === newHiddenWord.replace(/\s/g, "")) {
					setIsGameWon(true);
				}

				return newHiddenWord.trim();
			});
		} else {
			setRemainingAttempts(
				(prevRemainingAttempts) => prevRemainingAttempts - 1
			);
		}
	};

	const handleWin = async () => {
		try {
			setIsRewarded(true);
			const skillCoins = 2;
			const response = await axios.post(`${apiUrl}/game/${userID}`, {
				reward: SKILL_COIN_GAME,
				win: true,
			});
			if (response.status === 200) {
				console.log("Game reward updated");
				setShowRewardModal({
					show: true,
					reason: "winning the game",
					coinsCount: 2,
				}); // Show reward modal when rewarded
			}
		} catch (err) {
			console.error("Error updating game reward:", err);
		}
	};

	const handleLoss = async () => {
		try {
			const skillCoins = 0;
			const response = await axios.post(`${apiUrl}/game/${userID}`, {
				reward: skillCoins,
				win: false,
			});
			if (response.status === 200) {
				console.log("Game reward updated on loss");
			}
		} catch (err) {
			console.error("Error updating game reward:", err);
		}
	};

	useEffect(() => {
		if (remainingAttempts === 0) {
			handleLoss();
		}
	}, [remainingAttempts]);

	return loading ? (
		<div>
			<CustomLoader>
				<LoaderSpinner />
			</CustomLoader>
		</div>
	) : showPopup ? (
		<Popup>
			<p>You have already played the game today</p>
			<BackButton onClick={() => navigate(-1)}>Move to dashboard</BackButton>
		</Popup>
	) : (
		<GameContainer>
			<BackButton onClick={() => navigate(-1)}>Back</BackButton>
			<h1>Game</h1>
			<ul>
				<li>Click on the letters to guess</li>
				<li>Each wrong guess will reduce the remaining attempts</li>
				<li>Each correct guess will reveal the letter in the hidden word</li>
				<li>Win the game by guessing the correct word</li>
				<li>Everyday a new Word will be posted</li>
				<li>The winner will be rewarded with skillCoins</li>
			</ul>
			<HiddenWord>{hiddenWord}</HiddenWord>
			<Attempt>Remaining Attempts: {remainingAttempts}</Attempt>
			<ButtonWrapper>
				{[...Array(26)].map((_, index) => (
					<Button
						key={index}
						onClick={() => handleGuess(String.fromCharCode(97 + index))}
						disabled={remainingAttempts === 0 || isGameWon}
					>
						{String.fromCharCode(97 + index)}
					</Button>
				))}
			</ButtonWrapper>
			{showRewardModal.show && (
				<RewardModal
					onClose={() =>
						setShowRewardModal({ show: false, reason: "", coinsCount: 0 })
					}
					reason={showRewardModal.reason}
					coinsCount={showRewardModal.coinsCount}
				/>
			)}

			{isGameWon && !isRewarded && (
				<PopUpContainer>
					<PopUpContent>
						<VictoryText>Hurray!!!You won!</VictoryText>(
						<RewardButton onClick={() => handleWin()}>
							Claim Reward
						</RewardButton>
						)
					</PopUpContent>
				</PopUpContainer>
			)}
			{remainingAttempts === 0 && (
				<p>
					You lost! the correct word is <b>{word}</b>
				</p>
			)}
		</GameContainer>
	);
};

const VictoryText = styled.h4`
	font-size: 2rem;
	margin-bottom: 1rem;
	text-align: center;
	color: green;
`;

const PopUpContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
`;

const PopUpContent = styled.div`
	background-color: white;
	padding: 20px;
	border-radius: 5px;
`;
const Popup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	border: 1px solid #ccc;
`;

const GameContainer = styled.div`
	padding: 2rem;
`;
const RewardButton = styled.button`
	padding: 10px 20px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		background-color: #0056b3;
	}
`;
const Attempt = styled.p`
	font-size: 1.5rem;
	margin-bottom: 1rem;
	text-align: center;
`;

const HiddenWord = styled.p`
	font-size: 1.5rem;
	margin-bottom: 1rem;
	text-align: center;
`;

const ButtonWrapper = styled.div`
	width: 40%;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 0rem;
	margin-bottom: 1rem;
	justify-content: center;
	margin: 0 auto;
`;

const BackButton = styled.button`
	padding: 10px 20px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		background-color: #0056b3;
	}
`;

const Button = styled.button`
	font-size: 1rem;
	padding: 0.5rem 1rem;
	margin: 0.5rem;
	background-color: #4caf50;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;

	&:hover {
		background-color: #45a049;
	}

	&:disabled {
		background-color: #aaaaaa;
		cursor: not-allowed;
	}
`;
const CustomLoader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
`;

const LoaderSpinner = styled.div`
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-top: 4px solid #3498db;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export default Game;
