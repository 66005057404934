import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import API_URL from "../../config";

const SingleImplement = () => {
	const [records, setRecords] = useState([]);
	const apiUrl = API_URL;
	const { userID } = useParams();
	const navigate = useNavigate();
	// const [isRejectPermanent, setIsRejectPermanent] = useState(false);
	const [rejectionReason, setRejectionReason] = useState("");
	const [selectedReason, setSelectedReason] = useState("");
	const [isRejectPermanent, setIsRejectPermanent] = useState(false);
	const [showCustomReasonInput, setShowCustomReasonInput] = useState(false);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [otherUser, setOtherUser] = useState();
	const [currentTitle, setCurrentTitle] = useState();
	const [recordId, setRecordId] = useState();
	const commonReasons = [
		"Incomplete Profile",
		"Does Not Meet Requirements",
		"Violation of Terms of Service",
		"Duplicate Account",
		"Other",
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${apiUrl}/implements/${userID}`);
				const data = await response.json();
				console.log("Data", data);
				setRecords(data.records);
			} catch (error) {
				console.error("Failed to fetch data:", error);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		console.log("Updated Records", records);
	}, [records]);

	const acceptUser = async (otheruserId, title, id) => {
		try {
			console.log("Accepting user:", otheruserId);
			const response = await axios.post(`${apiUrl}/implements/${id}/accept`, {
				otheruserId: otheruserId,
				title: title,
			});
			const response2 = await axios.post(`${apiUrl}/user/chat/${userID}/open`, {
				otheruserId: otheruserId,
				title: title,
				message:
					"Welcome to Skill Swap! You guys are now connected to work on the project together.",
			});
			sendNotification(
				otheruserId,
				userID,
				"You have been accepted to the project and the Chat portal is opened for you to communicate with each user.",
				"acceptImplementUser"
			);
			console.log("Response from server222 from accept:", response2.data);
			console.log("Response from server from accept:", response.data);
			window.location.reload();
			// setIsAccepted(true);
		} catch (error) {
			console.error("Error occurred:", error);
		}
	};
	const rejectUser = async (otheruserId, title, id) => {
		// window.location.reload();
		try {
			if (rejectionReason === "" && selectedReason === "") {
				alert("Please select or provide a reason for rejection");
				return;
			}
			if (selectedReason === "Other" && rejectionReason === "") {
				alert("Please provide a reason for rejection");
				return;
			}

			console.log("Rejecting user:", otheruserId);
			const response = await axios.post(`${apiUrl}/implements/${id}/reject`, {
				otheruserId: otheruserId,
				title: title,
			});
			console.log("Response from server from reject:", response.data);
			if (selectedReason === "") {
				sendNotification(
					otheruserId,
					userID,
					"You have been rejected from the project" + rejectionReason,
					"rejectImplementUser"
				);
			} else {
				sendNotification(
					otheruserId,
					userID,
					"You have been rejected from the project because of " +
						selectedReason,
					"rejectImplementUser"
				);
			}
			window.location.reload();
		} catch (error) {
			console.error("Error occurred:", error);
		}
	};

	const rejectUserPermanent = async (otheruserId, title, id) => {
		try {
			let response;
			if (rejectionReason === "" && selectedReason === "") {
				alert("Please select or provide a reason for rejection");
				return;
			}
			if (selectedReason === "Other" && rejectionReason === "") {
				alert("Please provide a reason for rejection");
				return;
			}
			if (selectedReason != "Other") {
				console.log("Rejecting user permanently: not other", otheruserId);
				response = await axios.post(
					`${apiUrl}/implements/${id}/rejectPermanent`,
					{
						otheruserId: otheruserId,
						title: title,
						reason: selectedReason,
					}
				);
				sendNotification(
					otheruserId,
					userID,
					"You have been rejected permanently and you cannot join him anymore from the project because of " +
						selectedReason,
					"rejectImplementUser"
				);
			}
			if (selectedReason === "Other") {
				console.log("Rejecting user permanently:", otheruserId);
				response = await axios.post(
					`${apiUrl}/implements/${id}/rejectPermanent`,
					{
						otheruserId: otheruserId,
						title: title,
						reason: rejectionReason,
					}
				);
				sendNotification(
					otheruserId,
					userID,
					"You have been rejected permanently from the project because of " +
						rejectionReason,
					"rejectImplementUser"
				);
			}

			console.log(
				"Response from server from reject permanently:",
				response.data
			);
			window.location.reload();
		} catch (error) {
			console.error("Error occurred:", error);
		}
	};

	const sendNotification = async (otheruserId, currentUserId, message,type) => {
		try {
			const response = await axios.post(`${apiUrl}/notifications`, {
				userId: otheruserId,
				from_user: currentUserId,
				message: message,
				type: type,
			});
			console.log("Response from server from notification:", response.data);
		} catch (error) {
			console.error("Error occurred:", error);
		}
	};

	const handleReasonChange = (e) => {
		setSelectedReason(e.target.value);
		if (e.target.value === "Other") {
			setShowCustomReasonInput(true);
		} else {
			setShowCustomReasonInput(false);
			setRejectionReason("");
		}
	};

	const openPopup = (user, title, id) => {
		setIsPopupOpen(true);
		setOtherUser(user);
		setCurrentTitle(title);
		setRecordId(id);
		console.log("Other user", user);
		console.log("Current title", title);
		console.log("Record id", id);
	};

	const changeVisibility = async (postID, visible) => {
		try {
			const response = await axios.post(
				`${apiUrl}/implements/${userID}/${postID}/visibility`,
				{
					visibility: visible,
				}
			);
			console.log("Response from server from visibility:", response.data);
			window.location.reload();
		} catch (error) {
			console.error("Error occurred:", error);
		}
	};
	return (
		<Container>
			<BackButton onClick={() => navigate(-1)}>Back</BackButton>
			<Title1>Your Problems</Title1>
			{records &&
				records.map(
					(record) =>
						record && (
							<InnerContainer key={record._id}>
								<div>
									<Title>Title: {record.details.title}</Title>
									<Description>
										Description: {record.details.description}
									</Description>
									<Heading>Accepted Users:</Heading>
									<UserList>
										{record.details.acceptedUsers.length == 0 ? (
											<UserName>No users accepted yet</UserName>
										) : (
											record.details.acceptedUsers.map(
												(user) =>
													user.status == "accepted" && (
														<ProfileLink to={`/user/${user.user}`}>
															User: {user.userName}
														</ProfileLink>
													)
											)
										)}
									</UserList>
									{record.details.acceptedUsers.map(
										(user) =>
											user.status == "pending" && (
												<AcceptedDiv key={user._id}>
													<ProfileLink to={`/user/${user.user}`}>
														User: {user.userName}
													</ProfileLink>
													<Status>Description: {user.description}</Status>
													{user.status === "pending" ? (
														<div>
															<AcceptButton
																onClick={() =>
																	acceptUser(
																		user.user,
																		record.details.title,
																		record._id
																	)
																}
															>
																Accept
															</AcceptButton>
															<RejectButton
																onClick={() =>
																	// rejectUser(
																	//   user.user,
																	//   record.details.title,
																	//   record._id
																	// )

																	openPopup(
																		user.user,
																		record.details.title,
																		record._id
																	)
																}
															>
																Reject
															</RejectButton>
														</div>
													) : (
														<Status>Status: {user.status}</Status>
													)}
												</AcceptedDiv>
											)
									)}
								</div>
								<VisibilityButton
									visible={record.visible}
									onClick={() => {
										changeVisibility(record._id, !record.visible);
									}}
								>
									{record.visible ? "Hide Post" : "Show Post"}
								</VisibilityButton>
							</InnerContainer>
						)
				)}
			{isPopupOpen && (
				<PopupContainer>
					<PopInnerContainer>
						<Title>Are you sure you want to reject this user?</Title>
						<Reason>
							Please select or provide a reason for rejection
							<select value={selectedReason} onChange={handleReasonChange}>
								<option value="">Select a reason...</option>
								{commonReasons.map((reason) => (
									<option key={reason} value={reason}>
										{reason}
									</option>
								))}
							</select>
							{showCustomReasonInput && (
								<input
									type="text"
									value={rejectionReason}
									onChange={(e) => setRejectionReason(e.target.value)}
									placeholder="Enter other reason"
								/>
							)}
						</Reason>
						<Button
							onClick={() => {
								console.log("Rejecting once");
								console.log("Other user", otherUser);
								console.log("Current title", currentTitle);
								console.log("Record id", recordId);

								rejectUser(otherUser, currentTitle, recordId);
							}}
						>
							Reject Once
						</Button>
						<Button
							onClick={() => {
								setIsRejectPermanent(true);
								rejectUserPermanent(
									otherUser,
									currentTitle,
									recordId,
									rejectionReason,
									selectedReason
								);
							}}
						>
							Reject Permanently
						</Button>
						<Button onClick={() => setIsPopupOpen(false)}>Close</Button>
					</PopInnerContainer>
				</PopupContainer>
			)}
		</Container>
	);
};

const Container = styled.div`
	margin: 20px;
`;

const PopInnerContainer = styled.div`
	margin: 20px;
	padding: 20px;
	border: 1px solid #ccc;
	width: 50%;
	margin-left: 20%;
	border-radius: 5px;
	background-color: #fff;
`;

const PopupContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const Reason = styled.div`
	margin-bottom: 10px;
	select {
		margin-right: 10px;
	}
`;

const Button = styled.button`
	padding: 8px 20px;
	margin: 0 10px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 10px;
	&:hover {
		background-color: #0056b3;
	}
`;

const Title = styled.h2`
	font-size: 20px;
	color: #333;
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px;
`;

const Description = styled.p`
	font-size: 16px;
	color: #666;
	border-bottom: 1px solid #ccc;
	padding-bottom: 3px;
`;

const UserList = styled.ul`
	list-style-type: none;
	padding: 0;
`;

const UserName = styled.li`
	font-size: 16px;
	color: #888;
	border-bottom: 1px solid #ccc;
`;
const InnerContainer = styled.div`
	margin: 20px 0;
	padding: 20px;
	border: 1px solid #ccc;
	width: 50%;
	margin-left: 20%;
	border-radius: 5px;
`;

const Heading = styled.h3`
	font-size: 18px;
	color: #333;
	margin-bottom: 0px;
`;
const Title1 = styled.h1`
	font-size: 30px;
	color: #333;
	text-align: center;
	margin-top: 20px;
`;

const BackButton = styled.button`
	display: inline-block;
	background-color: #2e4052;
	color: white;
	padding: 10px 20px;
	text-decoration: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	margin-left: 20%;
	&:hover {
		background-color: #34495e;
	}
`;

const AcceptedDiv = styled.div`
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 15px;
`;

const ProfileLink = styled(Link)`
	color: #007bff;
	text-decoration: none;
	font-weight: bold;
	display: block;
	margin-bottom: 5px;
`;
const Status = styled.p`
	font-style: italic;
`;
const RejectButton = styled.button`
	margin-left: 5px;
	padding: 5px 10px;
	background-color: #dc3545;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background-color: #c82333;
	}
`;
const AcceptButton = styled.button`
	margin-right: 10px;
	padding: 5px 10px;
	background-color: #28a745;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background-color: #218838;
	}
`;

const VisibilityButton = styled.button`
	background-color: ${(props) => (props.visible ? "#ff6347" : "#32cd32")};
	color: white;
	font-size: 16px;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: ${(props) => (props.visible ? "#ff4500" : "#228b22")};
	}
`;

export default SingleImplement;
