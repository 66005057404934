import React from "react";
import API_URL from "../../config";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const WaitingPage = () => {
  const apiUrl = API_URL;
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUserId = async () => {
      try {
        // Retrieve the JWT token from localStorage
        const token = localStorage.getItem("jwt");
        if (!token) {
          // Handle the case where the token is not present
          console.error("JWT token not found in localStorage");
          navigate("/register");
          return;
        }
        const response = await axios.get(`${apiUrl}/dashboard/userId`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.userId) {
          console.log(response.data.userId);
          navigate(`/dashboard/${response.data.userId}`);
        } else {
          console.log("No user ID found");
          navigate("/register");
        }
      } catch (error) {
        console.error("Error fetching user ID:", error);
        navigate("/logout");
      }
    };
    fetchUserId();
  }, [navigate]);
  return (
    <Container>
      <Message>Loading...</Message>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Message = styled.div`
  font-size: 24px;
  text-align: center;
`;
export default WaitingPage;
