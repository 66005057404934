import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import API_URL from "../../config";
const Implement = () => {
	// const [userId, setUserId] = useState("");
	// const [userName, setUserName] = useState("");
	const [records, setRecords] = useState([]);
	const [skip, setSkip] = useState(0);
	const [isEnd, setIsEnd] = useState(false);

	const navigate = useNavigate();
	const apiUrl = API_URL;
	const { userID, userName } = useParams();
	// useEffect(() => {
	// 	const fetchUserDetails = async () => {
	// 		try {
	// 			// Retrieve the JWT token from localStorage
	// 			const token = localStorage.getItem("jwt");
	// 			if (!token) {
	// 				// Handle the case where the token is not present
	// 				console.error("JWT token not found in localStorage");
	// 				navigate("/register");
	// 				return;
	// 			}
	// 			// Make a request to the backend with the JWT token in the Authorization header
	// 			const response = await axios.get(`${apiUrl}/dashboard`, {
	// 				headers: { Authorization: `Bearer ${token}` },
	// 			});
	// 			if (response.data.error === "not filled details") {
	// 				navigate("/p");
	// 			}
	// 			console.log("User Id:", response.data.user);
	// 			// setUserId(response.data.user._id);
	// 			// setUserName(response.data.user.name);
	// 		} catch (error) {
	// 			console.error("Failed to fetch user details:", error);
	// 		}
	// 	};
	// 	fetchUserDetails();
	// }, []);
	useEffect(() => {
		const fetchRecords = async (skip) => {
			try {
				const response = await axios.get(`${apiUrl}/implements?skip=${skip}`);
				console.log("response from server", response.data);
				if (response.data.length === 0) {
					setIsEnd(true);
					return;
				}
				setRecords([...records, ...response.data]);
			} catch (error) {
				console.error("Error occurred", error);
			}
		};
		fetchRecords(skip);
	}, [skip]);

	const handleScroll = (e) => {
		console.log("scrolling came hereeee");
		const { scrollTop, clientHeight, scrollHeight } = e.target;
		if (scrollTop + clientHeight === scrollHeight) {
			setSkip(records?.length);
		}
	};

	return (
		<Container>
			<Holder>
				<BackButton onClick={() => navigate(-1)}>Back</BackButton>
				<BackButton to={`/implements/${userID}`}>My Problems</BackButton>
				<BackButton to={`/implements/${userID}/${userName}/create`}>
					Post Problem
				</BackButton>
			</Holder>

			<ScrollableContainer onScroll={handleScroll}>
				{console.log("records", records)}
				{records &&
					records.map(
						(implement) =>
							!(
								implement.owner === userID ||
								!implement.details.rejectedUsers.every(
									(user) => user.user !== userID
								)
							) && (
								<div key={implement._id}>
									{
										<Detail key={implement.details._id}>
											<Title>Title: {implement.details.title}</Title>
											<Status>
												Description: {implement.details.description}
											</Status>
											<ViewLink
												to={`/implements/${userID}/${userName}/${implement._id}`}
											>
												View
											</ViewLink>
										</Detail>
									}
								</div>
							)
					)}
				{isEnd && <p>End of the list</p>}
			</ScrollableContainer>
		</Container>
	);
};

const ScrollableContainer = styled.div`
	margin-top: 20px;
	max-height: 700px; // Adjust the height as needed
	overflow-y: auto; // Allow vertical scrolling
`;

const Holder = styled.div`
	display: flex;
	justify-content: space-between;
	position: sticky;
`;
const ViewLink = styled(Link)`
	display: inline-block;
	background-color: #2e4052;
	color: white;
	padding: 10px 20px;
	text-decoration: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	&:hover {
		background-color: #34495e;
	}
`;

const Container = styled.div`
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
`;

const BackButton = styled(Link)`
	display: inline-block;
	background-color: #2e4052;
	color: white;
	padding: 10px 20px;
	text-decoration: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	font-size: 16px; /* Default font size */

	@media (max-width: 768px) {
		padding: 8px 8px; /* Adjust padding for smaller screens */
		font-size: 14px; /* Adjust font size for smaller screens */
	}

	&:hover {
		background-color: #34495e;
	}
`;

const Detail = styled.div`
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 15px;
	margin-bottom: 20px;
`;
const Title = styled.h3`
	margin-bottom: 10px;
`;
const Status = styled.p`
	font-style: italic;
`;
export default Implement;
