import React, { useEffect } from "react";
import axios from "axios";
import API_URL from "../../../config";

function GameOver(props) {
	useEffect(() => {
		const updateHighScore = async () => {
			const token = localStorage.getItem("jwt");
			if (!token) {
				console.log("JWT token not found in localStorage");
				return;
			}
			try {
				const response1 = await axios.get(
					`${API_URL}/Snakegame/${props.userID}/details`
				);
				if (response1.data.highScore < props.score) {
					const response = await axios.post(
						`${API_URL}/Snakegame/${props.userID}/update`,
						{
							highScore: props.score,
						}
					);
					console.log(response);
				}
			} catch (err) {
				console.log(err);
			}
		};

		if (props.newHighScore) {
			console.log("High Score Updated12345678");
			updateHighScore();
		}
	}, []);

	return (
		<div
			id="GameBoard"
			style={{
				width: props.width,
				height: props.height,
				borderWidth: props.width / 50,
			}}
		>
			<div id="GameOver" style={{ fontSize: props.width / 15 }}>
				<div id="GameOverText">GAME OVER</div>
				<div>Your score: {props.score}</div>
				<div>
					{props.newHighScore ? "New local " : "Local "}high score:{" "}
					{props.highScore}
				</div>
				<div id="PressSpaceText">Press Space to restart</div>
			</div>
		</div>
	);
}

export default GameOver;
